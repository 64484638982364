import { CSSProperties, FC, useState, useContext } from "react";
import "./SepToolAudioUpload.css";
import { Alert, Snackbar } from "@mui/material";
import { useDropzone } from "react-dropzone";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import stateOfTheArtIcon from "../../assets/gifs/state-of-the-art.gif";
import unmixAudioIcon from "../../assets/gifs/unmix-audio.gif";
import React from "react";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import axios from "axios";
import { InputsContext, InputsContextProps, ISong } from "../../contexts/InputsContext";

interface SepToolAudioUploadProps {
  setDroppedFiles?: React.Dispatch<React.SetStateAction<File[]>>;
  droppedFiles?: File[];
  maxFile?: number;
  acceptOnlyAudioFiles?: boolean;
  style?: CSSProperties;
  className?: string;
  height?: string;
  width?: string;
}

const SepToolAudioUpload: FC<SepToolAudioUploadProps> = (props) => {
  const [isErrorUploadingFile, setIsErrorUploadingFile] =
    useState<boolean>(false);
  const [isLoadingUploadingFile, setIsLoadingUploadingFile] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0); // Ajout de l'état pour le pourcentage de chargement
  
  const maxFileSize = Number(process.env.REACT_APP_MAX_FILE_SIZE) || 200 * 1024 * 1024; // 200 MB par défaut
  const maxFileDuration = Number(process.env.REACT_APP_MAX_FILE_DURATION) || 120; // 2 minutes par défaut
  const { userEmail, userId, userToken } = React.useContext(UserContext) as UserContextProps;
  const { audioId, setAudioId } = React.useContext(InputsContext) as InputsContextProps;

  const onDropFile = (files: File[], fileRejections: any) => {
    setIsLoadingUploadingFile(true);

    if (fileRejections.length > 0) {
      setIsLoadingUploadingFile(false);
      setIsErrorUploadingFile(true);

      const errors = fileRejections[0].errors;
      if (errors.some((error: { code: string }) => error.code === 'file-too-large')) {
        setErrorMessage(`File is too large. Maximum size is ${maxFileSize / (1024 * 1024)} MB.`);
      } else if (errors.some((error: { code: string }) => error.code === 'too-many-files')) {
        setErrorMessage(`Too many files. Maximum number of files is ${props.maxFile}`);
      } else {
        setErrorMessage("File type not supported.");
      }
      return;
    }

    files.forEach((file) => {
      const audio = new Audio(URL.createObjectURL(file));
      audio.addEventListener("loadedmetadata", () => {
        if (audio.duration > 12000) {
          setIsLoadingUploadingFile(false);
          setIsErrorUploadingFile(true);
          setErrorMessage("File duration exceeds the 2 minutes limit.");
          return;
        }
        const formData = new FormData();
        formData.append("file", file);
  
        const isoDate = file.lastModified ? new Date(file.lastModified).toISOString() : undefined;
        
        const audio_infos = {
          "Name": file.name,
          "Duration": audio.duration,
          "Date": isoDate,
          "Format": file.type,
          "Artist": file.name,
        }
        const blob = new Blob([file], {
          type: file.type,
        });

        const body = new FormData();
        body.append("email", userEmail ? userEmail : "");
        body.append("audio_infos", JSON.stringify(audio_infos));
        body.append("user_id", userId ? userId : "");
        body.append("input", blob);

        var audio_id = "";

        const res = axios.post(`${process.env.REACT_APP_SERVER_URL}/upload`, body, {
          headers: {
            "Authorization": `Bearer ${userToken}`,
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        })
        .then((res: any) => {
          audio_id = res.data.audio_id;
          if (props.setDroppedFiles) {
            props.setDroppedFiles((prevFiles: any) => [...prevFiles, file]);
          }
          setIsUploadSuccess(true);
          setIsLoadingUploadingFile(false);
          setUploadProgress(0);
          if (setAudioId) { 
            setAudioId(audio_id);
          }
        })
        .catch((error: any) => {
          console.error("Upload error:", error);
          setIsErrorUploadingFile(true);
          setErrorMessage("An error occurred during the upload.");
        })
      });
    });
  };
  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      accept: props.acceptOnlyAudioFiles
        ? {
            "audio/wav": [".wav", ".flac"],
            "audio/raw": [".wav", ".flac"],
            "audio/mp3": [".mp3"],
          }
        : {},
      onDrop: onDropFile,
      maxFiles: props.maxFile ?? undefined,
      noKeyboard: true,
    });

  return (
    <div className="sep-tool-main-drag-and-drop-container-border-container">
      <div className="sep-tool-drag-and-drop-container-main-container">
        <Snackbar
          open={isErrorUploadingFile}
          onClose={() => setIsErrorUploadingFile(false)}
        >
          <Alert
            onClose={() => setIsErrorUploadingFile(false)}
            severity="error"
          >
            Error while uploading. Please try again later.
          </Alert>
        </Snackbar>
        <Snackbar
          open={isUploadSuccess}
          onClose={() => setIsUploadSuccess(false)}
        >
          <Alert onClose={() => setIsUploadSuccess(false)} severity="success">
            Files successfully uploaded!
          </Alert>
        </Snackbar>
        <div {...getRootProps({ className: "drag-and-drop-container" })}>
          <input {...getInputProps()} />
          {isLoadingUploadingFile ? (
            <div className="sep-tool-upload-in-progress-content">
              <SimpleSVGComponent
                icon={unmixAudioIcon}
                alt="unmix-audio-icon"
                cursor={false}
                height="3.5vw"
                width="3.5vw"
              />
              <span className="generic-big-bold-text">
                Uploading song... {uploadProgress}%
              </span>
            </div>
          ) : (
            <>
              {isDragActive ? (
                isDragAccept ? (
                  <span className="generic-big-bold-text">Dragging...</span>
                ) : (
                  <span className="generic-big-bold-text">
                    Can't drag this kind of file
                  </span>
                )
              ) : (
                <div className="sep-tool-upload-audio-dd-content">
                  <SimpleSVGComponent
                    icon={stateOfTheArtIcon}
                    alt="state-of-the-art-icon"
                    cursor={false}
                    height="3.5vw"
                    width="3.5vw"
                  />
                  <span
                    className="generic-big-bold-text"
                    style={{ lineHeight: "1.5vw" }}
                  >
                    Drag & Drop a song
                    <br />
                    or a folder to separate your track(s)
                  </span>
                  <span
                    className="generic-small-text"
                    style={{ textDecoration: "underline" }}
                  >
                    or browse file
                  </span>
                  <span
                    className="generic-small-text"
                    style={{ fontStyle: "italic", marginTop: "0.5vw" }}
                  >
                    (Only .wav & .mp3 are supported)
                  </span>
                </div>
              )}
            </>
          )}
        </div>{" "}
      </div>
    </div>
  );
};

export default SepToolAudioUpload;
