import React, { useCallback, useState, useEffect, useRef } from "react";
import "./SepToolPlayer.css";
import { GenericDropdownBasic } from "../UI/GenericDropdown";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import arrowIcon from "../../assets/icons/dropdown-arrow-icon.svg";
import DropdownArrow from "../../assets/icons/dropdown-arrow-icon.svg";
import PauseIcon from "../../assets/icons/pause-icon.svg";
import PlayerArrowIcon from "../../assets/icons/player-arrow-icon.svg";
import VolumeUpIcon from "../../assets/icons/volume-up-icon.svg";
import { VolumeMute } from "@mui/icons-material";
import { theme } from "../../constants/theme";
import { computeTime } from "../../tools/computeTime";
import {
  SepModelsContext,
  SepModelsContextProps,
} from "../../contexts/SepModelsContext";
import {
  InputsContext,
  InputsContextProps,
  ITrack
} from "../../contexts/InputsContext";
import * as Tone from "tone";
import {
  MultiPlayerContext,
  IMultiPlayerContext,
} from "../../contexts/MultiPlayerContext";
import { useKeyPress } from "../../hooks/UseKeyPress";

interface SepToolPlayerProps {
  // Define your props here
}

const mvpMode = process.env.REACT_APP_MVP_MODE === "true";

const SepToolPlayer: React.FC<SepToolPlayerProps> = (props) => {
  // Implement your component logic here
  const { sepModels, currentSelectedSepModel, setCurrentSelectedSepModel } =
    React.useContext(SepModelsContext) as SepModelsContextProps;
  const {
    modifySepModel,
    getAllInputsSongs,
    currentOutputTracks,
    currentSong,
    setCurrentSong,
    rescaleBuffer
  } = React.useContext(InputsContext) as InputsContextProps;

  const {
    players,
    setPlayers,
    setSoloForPlayer,
    setCurrentTime,
    currentTime,
    isPlaying,
    setIsPlaying,
    globalGain,
    setGlobalGain,
    cleanUpPlayers,
  } = React.useContext(MultiPlayerContext) as IMultiPlayerContext;

  // Audio Time
  const timeProgressBar = useRef<HTMLInputElement>(null);

  // Audio Playback
  const [duration, setDuration] = useState<number>(0);

  // Audio Volume
  const volumeBar = useRef<HTMLInputElement>(null);
  const gainNode = useRef<Tone.Gain>(new Tone.Gain(globalGain).toDestination());

  // useEffect(()=>{
  //   gainNode.set({gain: gain});
  // },[gain])

  useEffect(() => {
    if (players.length === 0) {
      setDuration(0);
      setCurrentTime(0);
      if (timeProgressBar.current) {
        timeProgressBar.current.value = "0";
      }
    }
  }, [players, setCurrentTime]);

  const getMaxBufferInfos = useCallback((newInputs: ITrack[]) => {
    let newMaxDuration = 0;
    let newMaxSampleRate = 0;

    newInputs.forEach((input) => {
      if (input.audioBuffer.duration > newMaxDuration)
        newMaxDuration = input.audioBuffer.duration;
      if (input.audioBuffer.sampleRate > newMaxSampleRate)
        newMaxSampleRate = input.audioBuffer.sampleRate;
    });
    return { newMaxDuration, newMaxSampleRate };
  }, []);

  // Set players filled with one player per input.audioBuffer
  const initPlayers = useCallback(() => {
    // clean up previous players
    Tone.start();
    const inputs = currentOutputTracks?.tracks;
    if (inputs === undefined) return;
    const newSolos = inputs.map(() => {
      const solo = new Tone.Solo();
      return solo;
    });
    const { newMaxDuration, newMaxSampleRate } = getMaxBufferInfos(inputs);
    const newPlayers = inputs.map((input, index) => {
      const player = new Tone.Player().sync().start(0).connect(gainNode.current);
      if (
        input.audioBuffer.duration !== newMaxDuration ||
        input.audioBuffer.sampleRate !== newMaxSampleRate
      ) {
        const rescaledInput = rescaleBuffer(
          input,
          newMaxDuration,
          newMaxSampleRate,
          0
        );
        player.buffer.set(rescaledInput.audioBuffer);
        return player;
      }
      player.buffer.set(input.audioBuffer);
      return player;
    });
    setCurrentTime(0);
    return { newPlayers, newSolos };
  }, [currentOutputTracks]);

  useEffect(() => {
    const inputs = currentOutputTracks?.tracks;
    if (inputs === undefined) return;
    if (inputs.length > 0) {
      const { newPlayers, newSolos } = initPlayers() as {
        newPlayers: Tone.Player[];
        newSolos: Tone.Solo[];
      };
      setSoloForPlayer(newSolos);
      setPlayers((prevPlayers: Tone.Player[]) => {
        prevPlayers.forEach((player) => {
          player.disconnect();
          player.dispose();
        });
        return newPlayers;
      });
      setDuration(Math.max(...inputs.map((list) => list.audioBuffer.duration)));
    }
  }, [currentOutputTracks, initPlayers, setSoloForPlayer, setPlayers]);

  const togglePlayPause = useCallback(() => {
    if (players.length === 0 || !timeProgressBar.current) return;
    if (isPlaying) {
      if (Tone.context.state !== "running") {
        Tone.start();
      }
      Tone.Transport.start();
    } else {
      Tone.Transport.pause();
    }
  }, [isPlaying, players]);

  useEffect(() => {
    togglePlayPause();
  }, [isPlaying, togglePlayPause]);

  useKeyPress(
    [" "],
    () => {
      setIsPlaying(!isPlaying);
    },
    [32]
  );

  // Update timeProgressBar value and currentTime
  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (isPlaying) {
      intervalId = setInterval(() => {
        if (players.length > 0 && isPlaying && timeProgressBar.current) {
          const currentTime = Tone.Transport.seconds;
          if (currentTime < duration) {
            timeProgressBar.current.value = String(currentTime);
            setCurrentTime(currentTime);
          } else {
            setIsPlaying(false);
            timeProgressBar.current.value = String(0);
            setCurrentTime(0);
            Tone.Transport.stop();
          }
        }
      }, 16);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isPlaying, duration, players, setIsPlaying, setCurrentTime]);

  const changeGain = useCallback(() => {
    if (volumeBar.current) {
      let newGain = Number(volumeBar.current.value);
      if (newGain!=globalGain)
        setGlobalGain(newGain);
      // gainNode.current.set({gain: newGain})
      gainNode.current.gain.rampTo(newGain, 0.1)

      console.log(newGain);
    }
  }, [volumeBar, globalGain]);

  useEffect(() => {
    changeGain();
  }, [changeGain]);


  const backwardAudio = () => {
    const inputs = currentOutputTracks?.tracks;
    if (inputs === undefined) return;
    if (players.length === 0 || !timeProgressBar.current) return;
    timeProgressBar.current.value = String(
      Number(timeProgressBar.current.value) - inputs[0].audioBuffer.duration / 5
    );
    timeProgressBar.current.style.setProperty(
      "--seek-before-width",
      `${(Number(timeProgressBar.current.value) / duration) * 100}%`
    );
    setCurrentTime(Number(timeProgressBar.current.value));
    Tone.Transport.seconds = Number(timeProgressBar.current.value);
  };

  const changeTimePosition = () => {
    if (players.length === 0 || !timeProgressBar.current) return;
    const currentTimeValue = Number(timeProgressBar.current.value);
    timeProgressBar.current.style.setProperty(
      "--seek-before-width",
      `${(currentTimeValue / duration) * 100}%`
    );
    setCurrentTime(currentTimeValue);
    Tone.Transport.seconds = Number(timeProgressBar.current.value);
  };

  const forwardAudio = () => {
    const inputs = currentOutputTracks?.tracks;
    if (inputs === undefined) return;
    if (players.length === 0 || !timeProgressBar.current) return;
    timeProgressBar.current.value = String(
      Number(timeProgressBar.current.value) + inputs[0].audioBuffer.duration / 5
    );
    timeProgressBar.current.style.setProperty(
      "--seek-before-width",
      `${(Number(timeProgressBar.current.value) / duration) * 100}%`
    );
    setCurrentTime(Number(timeProgressBar.current.value));
    Tone.Transport.seconds = Number(timeProgressBar.current.value);
  };

  // END OF PLAYER PART

  const setModelToSong = useCallback(
    (model: any) => {
      if (!currentSong || !model) return;
      modifySepModel(currentSong, model.title);
      setCurrentSelectedSepModel(model);
    },
    [currentSong, modifySepModel, setCurrentSelectedSepModel]
  );

  const songs = getAllInputsSongs();

  useKeyPress(
    ["ArrowLeft"],
    () => {
      if (songs.length > 0) {
        cleanUpPlayers();
        setCurrentSong(
          songs[
            songs.findIndex((song) => song === currentSong) !== 0
              ? songs.findIndex((song) => song === currentSong) - 1
              : songs.length - 1
          ]
        );
      }
    },
    [37]
  );

  useKeyPress(
    ["ArrowRight"],
    () => {
      if (songs.length > 0) {
        cleanUpPlayers();
        setCurrentSong(
          songs[
            (songs.findIndex((song) => song === currentSong) + 1) % songs.length
          ]
        );
      }
    },
    [39]
  );

  React.useEffect(() => {
    if (!currentSong && songs.length > 0) setCurrentSong(songs[0]);
  }, [songs, currentSong, setCurrentSong]);

  React.useEffect(() => {
    if (currentSong?.sepModel)
      setCurrentSelectedSepModel(
        sepModels.find((elem) => elem.title === currentSong.sepModel)
      );
    else setCurrentSelectedSepModel(undefined);
  }, [currentSong, setCurrentSelectedSepModel, sepModels]);

  return (
    <div className="source-sep-tool-border-container">
      <div className="source-sep-tool-main-container">
        <div className="source-sep-tool-song-model-selection">
          <div className="source-sep-tool-song-selection-title">Input song</div>
          <GenericDropdownBasic
            default="Select a song"
            style={{
              width: "9vw",
              height: "4vh",
              backgroundColor: theme.palette.purple,
              cursor: mvpMode ? "default" : "pointer",
            }}
            items={mvpMode ? [] : songs}
            currentSelectedItem={currentSong}
            setItemCallback={(item) => {
              if (mvpMode) return;
              cleanUpPlayers();
              setCurrentSong(item);
            }}
          />
          <div className="source-sep-tool-input-switch">
            <SimpleSVGComponent
              icon={arrowIcon}
              width="1.35vw"
              cursor={mvpMode ? false : true}
              height="auto"
              alt="Switch-right-arrow"
              style={{
                rotate: "-90deg",
                padding: "0.4vw",
                backgroundColor:
                  !mvpMode && currentSong && songs.indexOf(currentSong) === 0
                    ? theme.palette.black
                    : theme.palette.darkGrey,
                boxShadow: "-2px -3px 4px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "50%",
              }}
              onClick={() => {
                if (mvpMode) return;
                if (currentSong && songs.indexOf(currentSong) !== 0) {
                  const index = songs.indexOf(currentSong);
                  cleanUpPlayers();
                  if (index === 0) setCurrentSong(songs[songs.length - 1]);
                  else setCurrentSong(songs[index - 1]);
                }
              }}
            />
            <SimpleSVGComponent
              icon={arrowIcon}
              width="1.35vw"
              cursor={mvpMode ? false : true}
              height="auto"
              alt="Switch-right-arrow"
              style={{
                rotate: "90deg",
                padding: "0.4vw",
                backgroundColor:
                  !mvpMode && currentSong && songs.indexOf(currentSong) === songs.length - 1
                    ? theme.palette.black
                    : theme.palette.darkGrey,
                boxShadow: "2px 3px 4px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "50%",
              }}
              onClick={() => {
                if (mvpMode) return;
                if (
                  currentSong &&
                  songs.indexOf(currentSong) !== songs.length - 1
                ) {
                  const index = songs.indexOf(currentSong);
                  cleanUpPlayers();
                  if (index === songs.length - 1) setCurrentSong(songs[0]);
                  else setCurrentSong(songs[index + 1]);
                }
              }}
            />
          </div>
          <div className="source-sep-tool-model-selector-container">
            <GenericDropdownBasic
              default="Select a model"
              items={mvpMode ? [] : sepModels}
              currentSelectedItem={currentSelectedSepModel}
              setItemCallback={(item) => {
                if (mvpMode) return;
                cleanUpPlayers();
                setModelToSong(item);
              }}
              style={{
                cursor: mvpMode ? "default" : "pointer",
              }}
            />
          </div>
        </div>
        <div className="source-sep-tool-player-container">
          <div
            style={{
              height: "2vh",
              width: "2px",
              backgroundColor: "white",
              marginLeft: "1vw",
            }}
          ></div>
          <div className="source-sep-tool-right-player-buttons">
            <img
              src={PlayerArrowIcon}
              alt="go-backward-arrow"
              style={{ cursor: "pointer" }}
              onClick={backwardAudio}
            />
            <div
              className="source-sep-tool-right-button-container"
              onClick={() => setIsPlaying(!isPlaying)}
            >
              <img
                src={isPlaying ? PauseIcon : DropdownArrow}
                alt="play-button"
                style={{
                  transform: isPlaying ? "" : "rotate(90deg)",
                  height: "40%",
                  width: "auto",
                }}
              />
            </div>
            <img
              src={PlayerArrowIcon}
              alt="go-forward-arrow"
              style={{ transform: "rotate(180deg)", cursor: "pointer" }}
              onClick={forwardAudio}
            />
          </div>
          <div className="source-sep-tool-right-timer">
            <div className="generic-medium-bold-text" style={{ width: "2.73vw" }}>
              {computeTime(currentTime)}
            </div>
            <input
              type="range"
              className="audio-progress-bar"
              ref={timeProgressBar}
              value={currentTime}
              onChange={changeTimePosition}
              min={0}
              max={currentOutputTracks?.tracks ? currentOutputTracks?.tracks[0].audioBuffer.duration : 0}
              step={0.01}
              style={{ width: "10.87vw" }}
            />
            <div className="generic-medium-bold-text" style={{ width: "2.73vw" }}>
              {computeTime(currentOutputTracks?.tracks ? currentOutputTracks?.tracks[0].audioBuffer.duration : 0)}
            </div>
          </div>
          <div
            style={{ height: "2vh", width: "2px", backgroundColor: "white" }}
          ></div>
          <div className="source-sep-tool-right-volume-container">
            <div
              className="source-sep-tool-right-button-container"
              onClick={() => {setGlobalGain((globalGain==0) ? 0.9 : 0)}}
            >
              {(globalGain==0) ? (
                <VolumeMute style={{ height: "50%", width: "auto" }} />
              ) : (
                <img
                  src={VolumeUpIcon}
                  alt="play-button"
                  style={{
                    height: "40%",
                    width: "auto",
                  }}
                />
              )}
            </div>
            <input
              type="range"
              className="audio-progress-bar"
              ref={volumeBar}
              onChange={changeGain}
              value={globalGain}
              max={1}
              min={0}
              step={0.01}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SepToolPlayer;
