import React, { useState } from "react";
import "./SepToolUploadModelSelector.css";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import apiIcon from "../../assets/gifs/api.gif";
import { SplitButton } from "../SplitButton/SplitButton";
import { GenericDropdownBasic } from "../UI/GenericDropdown";
import unmixAudioIcon from "../../assets/gifs/unmix-audio.gif";
import {
  SepModelsContext,
  SepModelsContextProps,
} from "../../contexts/SepModelsContext";
import {
  InputsContext,
  InputsContextProps,
} from "../../contexts/InputsContext";
import { theme } from "../../constants/theme";
import { useParams } from "react-router-dom";
import CustomSnackBar from "../../components/UI/CustomSnackBar";
import { percentage } from "../../pages/SeparationToolPage/SeparationToolPage";

interface SepToolUploadModelSelectorProps {
  modalCallback?: () => void;
}

const SepToolUploadModelSelector: React.FC<SepToolUploadModelSelectorProps> = (
  props
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { sepModels, currentSelectedSepModel, setCurrentSelectedSepModel } =
    React.useContext(SepModelsContext) as SepModelsContextProps;
  const [sepModel, setSepModel] = React.useState<string | undefined>(undefined);
  const { modifySepModel, currentSong, fetchUpload } = React.useContext(
    InputsContext
  ) as InputsContextProps;
  const urlParams = useParams();
  const [error, setError] = React.useState<string | undefined>(undefined);

  const handleClick = async () => {
    if (currentSelectedSepModel && currentSong) {
      const isFetched = await fetchUpload(currentSong);
      if (isFetched) {
        props.modalCallback && props.modalCallback();
        setIsLoading(true);
      } else {
        if (isFetched === false)
          setError("Insufficient generattion credits for this account");
        else setError("An error occurred while separating the stems");
      }
    }
  }

  React.useEffect(() => {
    if (urlParams.id) {
      setIsLoading(true);
    }
  }, [urlParams.id, setIsLoading]);

  React.useEffect(() => {
    if (sepModel && currentSong) modifySepModel(currentSong, sepModel);
  }, [sepModel, currentSong, modifySepModel]);

  return (
    <div className="sep-tool-main-model-selector-container-border-container">
      <div className="sep-tool-model-selector-container-main-container">
        {!isLoading ? (
          <div className="sep-tool-model-selector-content">
            <SimpleSVGComponent
              icon={apiIcon}
              alt="state-of-the-art-icon"
              cursor={false}
              height="4.5vw"
              width="4.5vw"
            />
            <span
              className="generic-big-bold-text"
              style={{
                lineHeight: "1.2vw",
                fontSize: "1vw",
                marginTop: "-0.5vw",
              }}
            >
              Select a model to separate
              <br />
              your song
            </span>
            <GenericDropdownBasic
              default="Select a model"
              items={sepModels}
              style={{
                width: "9vw",
                height: "4vh",
                backgroundColor: theme.palette.lightGrey,
                opacity: "1",
              }}
              currentSelectedItem={currentSelectedSepModel}
              setItemCallback={setCurrentSelectedSepModel}
            />
            <SplitButton width="7.5vw" height="4vh" input={currentSong} onClick={handleClick} />
          </div>
        ) : (
          <div className="sep-tool-model-selector-generating-in-progress">
            <SimpleSVGComponent
              icon={unmixAudioIcon}
              alt="unmix-audio-icon"
              cursor={false}
              height="3.5vw"
              width="3.5vw"
            />
            <span className="generic-big-bold-text">
            {percentage === 100 ? (
              <>Displaying stems... 99%</>
            ) : percentage > 0 && percentage < 100 ? (
                <>Loading stems... {percentage < 99 ? percentage : 99}%</>
              ) : (
                <>Separating stems...</>
              )}
            </span>
          </div>
        )}
      </div>
      {error && (
        <CustomSnackBar
          onClose={() => setError(undefined)}
          open={error.length > 0}
          text={error}
          severity="error"
        />
      )}
    </div>
  );
};

export default SepToolUploadModelSelector;
