import React, { FC, useEffect, useState, useContext } from "react";
import "./SeparationToolPage.css";
import SeparationToolHeader from "./SeparationToolHeader";
import OutputsList from "../../components/OutputsList/OutputsList";
import { useKeyPress } from "../../hooks/UseKeyPress";
import SimpleSVGComponent from "../../components/UI/SimpleSVGComponent";
import downArrowIcon from "../../assets/septool/down-arrow-icon.svg";
import downloadIcon from "../../assets/icons/download-icon.svg";
import bellIcon from "../../assets/septool/bell-icon.svg";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { theme } from "../../constants/theme";
import arrowIcon from "../../assets/septool/add-arrow-icon.svg";
import downloadAudioIcon from "../../assets/septool/download-audio-icon.svg";
import SeparationToolFooter from "./SeparationToolFooter";
import SepToolAudioUpload from "../../components/SepToolAudioUpload/SepToolAudioUpload";
import SepToolUploadModelSelector from "../../components/SepToolUploadModelSelector/SepToolUploadModelSelector";
import axios from 'axios';


import {
  ITrack,
  InputsContext,
  InputsContextProps,
} from "../../contexts/InputsContext";
import {
  SepModelsContext,
  SepModelsContextProps,
} from "../../contexts/SepModelsContext";
import JSZip from "jszip";
import saveAs from "file-saver";
import {
  genericFetch,
  UserContext,
  UserContextProps,
} from "../../contexts/UserContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GenericButton } from "../../components/UI/GenericButton";
import ArrowIcon from "../../assets/icons/arrow-icon.svg";
import MailIcon from "../../assets/icons/mail-icon.svg";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { jwtDecode } from "jwt-decode";
import GenericModal from "../../components/UI/GenericModal/GenericModal";
import CustomSnackBar from "../../components/UI/CustomSnackBar";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: theme.palette.darkGrey,
  padding: 3,
  filter: "drop-shadow(0px 0.2vw 0.4vw rgba(0, 0, 0, 0.25))",
  borderRadius: "0.4vw",
  gap: "1vw",
};

let percentage = 0;

const DownloadTooltip = () => {
  const [downloadMode, setDownloadMode] = useState<".MP3" | ".WAV">(".MP3");
  const { currentSong } = React.useContext(InputsContext) as InputsContextProps;
  const { getModelIndex } = React.useContext(
    SepModelsContext
  ) as SepModelsContextProps;
  const [pushAlert, setPushAlert] = useState<string | undefined>(undefined);
  const [percentCompleted, setPercentCompleted] = useState<number>(0);
  const [downloadFinished, setDownloadFinished] = React.useState<boolean>(false);
  const [showDiv, setShowDiv] = useState(false);
  const [exportClicked, setExportClicked] = useState(false);


  interface TitleProps {
    title: ".MP3" | ".WAV";
    icon: string;
  }

  const mp3AudiosAlreadyExist = async (trackName: string | undefined) => {
    if (trackName) {
      const filename = trackName.split(".")[0] + ".mp3";
      if (currentSong === undefined) return false;
      let model_index = getModelIndex(currentSong.sepModel);
      if (model_index === -1) {
        model_index = 0;
      }
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/media/` +
          (currentSong?.outputs[model_index]?.id || "") +
          "/" +
          filename
      );
      if (res.status === 404) return false;
      return true;
    }
    return false;
  };

  //Tooltip for download
  const handleDownload = async () => {
    setShowDiv(true);
    setExportClicked(true);
    let originalTitle = currentSong?.title;
    if (currentSong?.title && currentSong?.title.endsWith(".wav"))
      originalTitle = currentSong?.title.slice(0, -4);
    else if (currentSong?.title && currentSong?.title.endsWith(".mp3"))
      originalTitle = currentSong?.title.slice(0, -4);
  
    if (currentSong) {
      if (downloadMode === ".MP3") {
        let audioAlreadyTranscribed = undefined;
        let currentTracks = undefined;
        let id: string | undefined = undefined;
        let totalFiles: number = 0;
  
        if (currentSong.outputs?.[getModelIndex(currentSong.sepModel)]) {
          audioAlreadyTranscribed = await mp3AudiosAlreadyExist(
            currentSong.outputs?.[getModelIndex(currentSong.sepModel)]
              ?.tracks?.[0]?.name
          );
          currentTracks =
            currentSong.outputs?.[getModelIndex(currentSong.sepModel)].tracks;
          id = currentSong.outputs[getModelIndex(currentSong.sepModel)].id;
        } else {
          audioAlreadyTranscribed = await mp3AudiosAlreadyExist(
            currentSong.outputs?.[0]?.tracks?.[0]?.name
          );
          currentTracks = currentSong.outputs?.[0]?.tracks;
          id = currentSong.outputs[0].id;
        }
  
        if (currentTracks) {
          totalFiles = currentTracks.length;
        }
  
        const progressArray = new Array(totalFiles).fill(0);
        let tracks: { name: string; blob: Blob }[] = [];
  
        if (audioAlreadyTranscribed === true && currentTracks) {
          await Promise.all(
            currentTracks.map(async (track: ITrack, index: number) => {
              const filename = track.name.split(".")[0] + ".mp3";
              const url = `${process.env.REACT_APP_SERVER_URL}/media/` + id + "/" + filename;
              try {
                const res = await axios.get(url, {
                  responseType: 'blob',
                  onDownloadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                      let percentCompleted = (progressEvent.loaded / progressEvent.total) * 100;
                      progressArray[index] = percentCompleted;
                      setPercentCompleted(
                        Math.round(progressArray.reduce((acc, curr) => acc + curr, 0) / totalFiles)
                      );
                    }
                  },
                });
  
                if (res.status === 200) {
                  const blob = res.data;
                  tracks.push({ name: filename, blob });
                }
              } catch (error) {
                console.error('Erreur lors du téléchargement du fichier', error);
              }
            })
          );
  
          if (tracks.length > 0) {
            const zip = new JSZip();
            const requests = tracks.map((track) => {
              zip.file(
                originalTitle ? `${originalTitle}_${track.name}` : track.name,
                track.blob
              );
              return null;
            });
            await Promise.all(requests);
            await zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(
                content,
                originalTitle ? `${originalTitle}_tracks.zip` : "tracks.zip"
              );
            });
          }
        } else {
          const body = {
            audio_id: id,
            filenames: currentSong.outputs.flatMap((output) =>
              output.tracks?.map((track) => track.name)
            ),
          };
          
          const res = await genericFetch({
            url: "/transcribe",
            method: "POST",
            body: body,
          });
          console.log(res);

  
          // Check if `res` is an array before mapping
          if (res.Error) {
            console.error("Unexpected response format from /transcribe API:", res.Error);
          
          }else if (Array.isArray(res)) {
            if (Array.isArray(res)) {
            await Promise.all(
              res.map(async (output: any) => {
                const filename = output.substring(output.lastIndexOf("/") + 1);
                const res = await fetch(
                  `${process.env.REACT_APP_SERVER_URL}/media/` + id + "/" + filename
                );
                if (res.ok) {
                  const blob = await res.blob();
                  tracks.push({ name: filename, blob });
                }
              })
            );
          } else {
            console.error("Unexpected response format from /transcribe API:", res);
          }
  
            if (tracks.length > 0) {
              const zip = new JSZip();
              const requests = tracks.map((track) => {
                zip.file(
                  originalTitle ? `${originalTitle}_${track.name}` : track.name,
                  track.blob
                );
                return null;
              });
              await Promise.all(requests);
              await zip.generateAsync({ type: "blob" }).then((content) => {
                saveAs(
                  content,
                  originalTitle ? `${originalTitle}_tracks.zip` : "tracks.zip"
                );
              });
            }
          } else {
            console.error("Unexpected response format from /transcribe API:", res);
          }
        }
      } else {
        let tracks = undefined;
        if (
          currentSong.outputs[getModelIndex(currentSong.sepModel)] === undefined
        ) {
          tracks = currentSong.outputs[0].tracks;
        } else {
          tracks =
            currentSong.outputs[getModelIndex(currentSong.sepModel)].tracks;
        }
  
        if (tracks) {
          const zip = new JSZip();
          const requests = tracks.map((track) => {
            zip.file(
              originalTitle ? `${originalTitle}_${track.name}` : track.name,
              track.blob
            );
            return null;
          });
          await Promise.all(requests);
          await zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(
              content,
              originalTitle ? `${originalTitle}_tracks.zip` : "tracks.zip"
            );
          });
        }
      }
    }
    setDownloadFinished(true);
  };
  

  useEffect(() => {
    if (downloadFinished) {
      const timer = setTimeout(() => {
        setShowDiv(false);
        setDownloadFinished(false);
        setExportClicked(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [downloadFinished]);

  const Title: FC<TitleProps> = ({ title, icon }: TitleProps) => {
    return (
      <div className="tooltip-title-container">
        <div
          className="tooltip-title"
          style={
            title === downloadMode ? { backgroundColor: "rgb(26, 25, 30)" } : {}
          }
          onClick={() => setDownloadMode(title)}
        >
          <SimpleSVGComponent
            icon={icon}
            alt="title-icon"
            height="1vw"
            width="1vw"
          />
          <div className="tootltip-title-text">{title}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="download-tooltip-container">
      <div className="download-tooltip-title-container">
        <SimpleSVGComponent
          alt="bell-icon"
          icon={bellIcon}
          height="2vh"
          width="2vh"
        />
        <div className="download-tooltip-title">Export Settings</div>
        {downloadMode === ".WAV" && exportClicked &&(
          <div>
          {downloadFinished ? "Download complete" : "Zipping files..."}
        </div>
        )}
        {downloadMode === ".MP3" && showDiv && exportClicked &&(
        <div>
          {percentCompleted === 100 ? (downloadFinished ? "Download complete" : "Zipping files...") : `Downloading tracks... ${percentCompleted}%`}
        </div>
      )}
      </div>
      <div className="download-tooltip-main-container">
        <div className="download-tooltip-main">
          <Title icon={downloadAudioIcon} title=".MP3" />
        </div>
        <div className="download-tooltip-main">
          <Title icon={downloadAudioIcon} title=".WAV" />
        </div>
      </div>
      <div className="download-tooltip-footer">
        <div
          className="download-tooltip-export-container"
          onClick={handleDownload}
        >
          <div className="download-tooltip-export-background">
            <div className="download-tooltip-export">Export</div>
            <SimpleSVGComponent
              icon={arrowIcon}
              alt="arrow-icon"
              height="auto"
              width="10px"
            />
          </div>
        </div>
      </div>
      {pushAlert && (
        <CustomSnackBar
          onClose={() => setPushAlert(undefined)}
          open={pushAlert.length > 0}
          text={pushAlert}
          severity="success"
        />
      )}
    </div>
  );
};

interface CustomTooltipProps extends TooltipProps {
  width?: number;
  backgroundColor?: string;
}

export const CustomTooltip = styled(
  ({ className, ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ width, backgroundColor }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: backgroundColor ?? theme.palette.lightGrey,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: width || 500,
    backgroundColor: backgroundColor ?? theme.palette.lightGrey,
  },
}));

interface SeparationToolPageProps {}

const SeparationToolPage: React.FC<SeparationToolPageProps> = (props) => {
  const [showDownload, setShowDownload] = useState<boolean>(false);
  const [isSepToolOpen, setIsSepToolOpen] = useState(true);
  const [isOutputListAtTop, setIsOutputListAtTop] = useState<boolean>(true);
  const [isOutputListAtBottom, setIsOutputListAtBottom] =
    useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(1.0);
  const maxZoom = 10.0;
  const minZoom = 1.0;
  const {
    inputs,
    addInput,
    setCurrentOutputTracks,
    currentSong,
    setCurrentSong,
    IsGenerationError,
  } = useContext(InputsContext) as InputsContextProps;
  const [isAudioUploaded, setIsAudioUploaded] = useState<boolean>(false);
  const [isModelSelected, setIsModelSelected] = useState<boolean>(false);
  const [droppedFiles, setDroppedFiles] = React.useState<File[]>([]);
  const {
    currentSelectedSepModel,
    setCurrentSelectedSepModel,
    currentSelectedSepModelIsGenerated,
    setCurrentSelectedSepModelIsGenerated,
    getModelIndex,
  } = useContext(SepModelsContext) as SepModelsContextProps;
  const urlParams = useParams();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const handleModalOpen = () => setModalIsOpen(true);
  const handleModalClose = () => setModalIsOpen(false);
  const { userEmail, credits } = useContext(UserContext) as UserContextProps;
  const [incorrectId, setIncorrectId] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [downloadOnly, setDownloadOnly] = React.useState<boolean>(false);
  const [completpercentage, setCompletpercentage] = React.useState<number>(0);
  const [downloadfinished, setDownloadfinished] = React.useState<boolean>(false);
  const [allTracksLoaded, setAllTracksLoaded] = useState<boolean>(false);



  const mp3AudiosAlreadyExist = async (trackName: string | undefined) => {
    if (trackName) {
      const filename = trackName.split(".")[0] + ".mp3";
      if (currentSong === undefined) return false;
      let model_index = getModelIndex(currentSong.sepModel);
      if (model_index === -1) {
        model_index = 0;
      }
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/media/` +
          (currentSong?.outputs[model_index]?.id || "") +
          "/" +
          filename
      );
      if (res.status === 404) return false;
      return true;
    }
    return false;
  };

  const checkIfAllTracksLoaded = (tracks: ITrack[]) => {
    if (tracks.length > 0) {
      setAllTracksLoaded(true);  // Mettez à jour cet état lorsque toutes les pistes sont prêtes
    }
  };

  useEffect(() => {
    if (IsGenerationError) {
      handleModalClose();
      setError("An error occurred while separating the stems");
      setIsModelSelected(false);
      setCurrentSelectedSepModel(undefined);
    }
  }, [IsGenerationError]);

  // useEffect(() => {
  //   // get query param from url
  //   const urlParamQuery = new URLSearchParams(window.location.search);
  //   console.log("URL PARAMS", window.location.search);
  //   const paramValue = urlParamQuery.get("download");
  //   if (paramValue === "mp3" || paramValue === "wav") setDownloadOnly(true);
  //   console.log("DOWNLOAD ONLY", downloadOnly);
  // }, []);

  //Here we download the wavs on page load
  useEffect(() => {
    // console.log(downloadOnly);
    //if (downloadOnly) return
    // console.log("DOWNLOAD PAGE");
    if (!urlParams.id) return;
    const currentDate = new Date();
    try {
      const audioId = urlParams.id.split(":")[1];
      const userId = urlParams.id.split(":")[0];
      const decoded = jwtDecode(audioId); //This id hides the expiration date of the project
      if (decoded.exp && decoded.exp * 1000 < currentDate.getTime())
        setDeleted(true);
      else if (decoded.exp) {
        setIsAudioUploaded(true);
        setIsModelSelected(false);
        setCurrentSelectedSepModelIsGenerated(false);

        const urlParamQuery = new URLSearchParams(window.location.search);
        const download = urlParamQuery.get("download");

        const fetchAudioInfos = async () => {
          if (audioId && userId) {
            const res = await genericFetch({
              url: "/retrieve-audio-infos/" + urlParams.id,
              method: "GET",
            });
            if (!res.error) {
              const totalFiles = res.stems.length;
              const progressArray = new Array(totalFiles).fill(0);
              if (res.stems && res.stems.length > 0) {
                const tempAudios: ITrack[] = [];
                if (download=="mp3"){
                  res.stems.map(async (elem: any, index: number) => {
                    const blob = new Blob();
                    tempAudios.push({
                      type: elem["Stem Type"][0],
                      color: elem["Stem Type"][1],
                      //audiobuffer will be a defalut empty buffer
                      audioBuffer : new AudioContext().createBuffer(2, 44100, 44100),
                      name: elem["Stem Track Path"],
                      blob: blob,
                    });
                  });
                } else {
                  await Promise.all(
                    res.stems.map(async (elem: any, index: number) => {
                      const url = `${process.env.REACT_APP_SERVER_URL}/media/${audioId}/${elem["Stem Track Path"]}`;
                      try {
                        const response = await axios.get(url, {
                          responseType: 'blob',
                          onDownloadProgress: (progressEvent) => {
                            const percentCompleted = (progressEvent.loaded / progressEvent.total) * 100;
                            progressArray[index] = percentCompleted;
    
                            setCompletpercentage(Math.round(progressArray.reduce((acc, curr) => acc + curr, 0) / totalFiles));
                          },
                        });
    
                        if (response.status === 200) {
                          const audioContext = new AudioContext();
                          const blob = response.data;
                          const file = new File([blob], elem["Stem Track Path"], {
                            type: blob.type,
                          });
                          const audioData = await file.arrayBuffer();
                          const audioBuffer = await audioContext.decodeAudioData(audioData);
                          tempAudios.push({
                            type: elem["Stem Type"][0],
                            color: elem["Stem Type"][1],
                            audioBuffer,
                            name: elem["Stem Track Path"],
                            blob: blob,
                          });
                        }
                      } catch (error) {
                        console.error('Erreur lors du téléchargement du fichier', error);
                      }
                    })
                  );
                }
                setCurrentOutputTracks({
                  model: res.stemTrackModel,
                  tracks: tempAudios,
                });
                setCurrentSong({
                  file: undefined,
                  audioBuffer: tempAudios[0].audioBuffer,
                  title: res.originalTrackInfo.Name,
                  duration: tempAudios[0].audioBuffer.duration,
                  added: false,
                  artist: undefined,
                  format: undefined,
                  date: undefined,
                  outputs: [
                    {
                      model: res.stemTrackModel,
                      tracks: tempAudios,
                      id: res.audio_id,
                    },
                  ],
                  sepModel: res.stemTrackModel,
                  contentDetails: undefined,
                });
              }
            } else setDeleted(true);
          }
        };
        // Download on page load
        fetchAudioInfos();
      } else {
        setIncorrectId(true);
      }
    } catch (e) {
      setIncorrectId(true);
    }
  }, [
    urlParams.id,
    navigate,
    setIsAudioUploaded,
    setIsModelSelected,
    setCurrentSelectedSepModelIsGenerated,
  ]);

  percentage = completpercentage;
  

  /*   useEffect(() => {
    if (!urlParams.id) return;
    const fetchAudios = async () => {
      if (json) {
        const tempAudios: ITrack[] = [];
        await Promise.all(
          json.outputs.map(async (output: any, index: number) => {
            const filename = output.substring(output.lastIndexOf("/") + 1);
            const res = await fetch(
              `${process.env.REACT_APP_SERVER_URL}/media/` +
                urlParams.id +
                "/" +
                filename
            );
            if (res.ok) {
              const audioContext = new AudioContext();
              const blob = await res.blob();
              const file = new File([blob], filename, { type: blob.type });
              const audioData = await file.arrayBuffer();
              const audioBuffer = await audioContext.decodeAudioData(audioData);
              tempAudios.push({
                type: json.types[index][0],
                color: json.types[index][1],
                audioBuffer,
                name: filename,
                blob: blob,
              });
            }
          })
        );
        setCurrentOutputTracks({
          model: json.model,
          tracks: tempAudios,
        });
        setCurrentSong({
          file: undefined,
          audioBuffer: tempAudios[0].audioBuffer,
          title: json.filename,
          duration: tempAudios[0].audioBuffer.duration,
          added: false,
          artist: undefined,
          format: undefined,
          date: undefined,
          outputs: json.outputs.map((output: any, index: number) => {
            return {
              model: json.model,
              tracks: tempAudios,
              id: output,
            };
          }),
          sepModel: json.model,
          contentDetails: undefined,
        });
      }
    };
    fetchAudios();
  }, [
    json,
    urlParams.id,
    setCurrentSelectedSepModelIsGenerated,
    setCurrentOutputTracks,
    setCurrentSong,
  ]);
 */
  React.useEffect(() => {
    // get query param from url
    const urlParamQuery = new URLSearchParams(window.location.search);
    const paramValue = urlParamQuery.get("download");
    if (paramValue === "mp3" || paramValue === "wav") setDownloadOnly(true);
    if (currentSong && currentSong.outputs) {
      const currentSepModel = currentSong.outputs.find(
        (output) => output.model === currentSelectedSepModel?.title
      );
      if (currentSepModel?.tracks === undefined) {
        setCurrentSelectedSepModelIsGenerated(true);
        setCurrentOutputTracks({
          model: currentSong.sepModel,
          tracks: currentSong.outputs[0].tracks,
        });
      }
      if (currentSepModel && currentSepModel.tracks) {
        setCurrentSelectedSepModelIsGenerated(true);
        setCurrentOutputTracks(currentSepModel);
      }
      if (
        currentSepModel &&
        (currentSepModel.tracks === undefined ||
          currentSepModel.tracks.length === 0)
      ) {
        setCurrentSelectedSepModelIsGenerated(false);
        setCurrentOutputTracks(undefined);
      }
      if (paramValue === "mp3" || paramValue === "wav") {
        handleDownload(paramValue === "mp3" ? ".MP3" : ".WAV");
      }
    }
  }, [
    currentSong,
    currentSelectedSepModel,
    setCurrentSelectedSepModelIsGenerated,
    setCurrentOutputTracks,
  ]);

  React.useEffect(() => {
    const addFiles = async () => {
      await addInput(droppedFiles);
      setDroppedFiles([]);
    };
    if (droppedFiles.length > 0) {
      addFiles();
    }
  }, [droppedFiles, addInput]);

  useEffect(() => {
    if (inputs.length !== 0) {
      setIsAudioUploaded(true);
    }
  }, [inputs]);

  const zoomOut = () => {
    if (Math.ceil(zoom) === minZoom) return;
    const newZoom = zoom - 0.1;
    setZoom(newZoom);
  };

  const zoomIn = () => {
    if (Math.ceil(zoom) === maxZoom) return;
    const newZoom = zoom + 0.1;
    setZoom(newZoom);
  };

  useKeyPress(
    ["+"],
    () => {
      zoomIn();
    },
    [187]
  );

  useKeyPress(
    ["-"],
    () => {
      zoomOut();
    },
    [189]
  );

  // check for scroll events
  useEffect(() => {
    const element = document.getElementById("source-sep-outputs-list");
    if (element) {
      element.onscroll = () => {
        if (Math.abs(element.scrollTop) <= 1) {
          setIsOutputListAtTop(true);
          setIsOutputListAtBottom(false);
        } else if (
          Math.abs(
            element.scrollHeight - element.clientHeight - element.scrollTop
          ) <= 1
        ) {
          setIsOutputListAtBottom(true);
          setIsOutputListAtTop(false);
        } else {
          setIsOutputListAtBottom(false);
          setIsOutputListAtTop(false);
        }
      };
    }
  }, []);

  //here, wavs have been downloaded already. If mp3, download mp3s and zip. if wav, zip
  const handleDownload = async (downloadMode: ".MP3" | ".WAV") => {
    let originalTitle = currentSong?.title;
    if (currentSong?.title && currentSong?.title.endsWith(".wav"))
      originalTitle = currentSong?.title.slice(0, -4);
    else if (currentSong?.title && currentSong?.title.endsWith(".mp3"))
      originalTitle = currentSong?.title.slice(0, -4);
    if (currentSong) {
      if (downloadMode === ".MP3") {
        let audioAlreadyTranscribed = undefined;
        let currentTracks = undefined;
        let id: string | undefined = undefined;
        let totalFiles : number = 0;
        if (currentSong.outputs?.[getModelIndex(currentSong.sepModel)]) {
          audioAlreadyTranscribed = await mp3AudiosAlreadyExist(
            currentSong.outputs?.[getModelIndex(currentSong.sepModel)]
              ?.tracks?.[0]?.name
          );
          currentTracks =
            currentSong.outputs?.[getModelIndex(currentSong.sepModel)].tracks;
          id = currentSong.outputs[getModelIndex(currentSong.sepModel)].id;
        } else {
          audioAlreadyTranscribed = await mp3AudiosAlreadyExist(
            currentSong.outputs?.[0]?.tracks?.[0]?.name
          );
          currentTracks = currentSong.outputs?.[0]?.tracks;
          id = currentSong.outputs[0].id;
        }
        let tracks: { name: string; blob: Blob }[] = [];
        //get total number of tracks
        if (currentTracks) {
          totalFiles = currentTracks.length;
        }
        const progressArray = new Array(totalFiles).fill(0);
        if (audioAlreadyTranscribed === true && currentTracks) {
          await Promise.all(
            currentTracks.map(async (track: ITrack, index: number) => {
              const filename = track.name.split(".")[0] + ".mp3";
              const url = `${process.env.REACT_APP_SERVER_URL}/media/` + id + "/" + filename;
              try {
                const res = await axios.get(url, {
                  responseType: 'blob',
                  onDownloadProgress: (progressEvent) => {
                    const percentCompleted = (progressEvent.loaded / progressEvent.total) * 100;
                    progressArray[index] = percentCompleted;
                    setCompletpercentage(Math.round(progressArray.reduce((acc, curr) => acc + curr, 0) / totalFiles));
                  },
                });
                if (res.status === 200) {
                  const blob = res.data;
                  tracks.push({ name: filename, blob });
                }
              } catch (error) {
                console.error('Erreur lors du téléchargement du fichier', error);
              }
            })
          );
          if (tracks.length > 0) {
            const zip = new JSZip();
            const requests = tracks.map((track) => {
              zip.file(
                originalTitle ? `${originalTitle}_${track.name}` : track.name,
                track.blob
              );
              return null;
            });
            await Promise.all(requests);
            await zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(
                content,
                originalTitle ? `${originalTitle}_tracks.zip` : "tracks.zip"
              );
            }).then(() => {
              setDownloadfinished(true);
            });
          }
        } else {
          const body = {
            audio_id: id,
            filenames: currentSong.outputs.flatMap((output) =>
              output.tracks?.map((track) => track.name)
            ),
          };
          const res = await genericFetch({
            url: "/transcribe",
            method: "POST",
            body: body,
          });
          if (!res.error) {
            await Promise.all(
              res.map(async (output: any, index: number) => {
                const filename = output.substring(output.lastIndexOf("/") + 1);
                const url = `${process.env.REACT_APP_SERVER_URL}/media/` + id + "/" + filename;
                try {
                  const res = await axios.get(url, {
                    responseType: 'blob',
                    onDownloadProgress: (progressEvent) => {
                      const percentCompleted = (progressEvent.loaded / progressEvent.total) * 100;
                      progressArray[index] = percentCompleted;
                      setCompletpercentage(Math.round(progressArray.reduce((acc, curr) => acc + curr, 0) / totalFiles));
                    },
                  });
                  if (res.status === 200) {
                    const blob = res.data;
                    tracks.push({ name: filename, blob });
                  }
                } catch (error) {
                  console.error('Erreur lors du téléchargement du fichier', error);
                }
              })
            );
            if (tracks.length > 0) {
              const zip = new JSZip();
              const requests = tracks.map((track) => {
                zip.file(
                  originalTitle ? `${originalTitle}_${track.name}` : track.name,
                  track.blob
                );
                return null;
              });
              await Promise.all(requests);
              await zip.generateAsync({ type: "blob" }).then((content) => {
                saveAs(
                  content,
                  originalTitle ? `${originalTitle}_tracks.zip` : "tracks.zip"
                );
              }).then(() => {
                setDownloadfinished(true);
              });
            }
          }
        }
      } else {
        let tracks = undefined;
        if (
          currentSong.outputs[getModelIndex(currentSong.sepModel)] === undefined
        ) {
          tracks = currentSong.outputs[0].tracks;
        } else {
          tracks =
            currentSong.outputs[getModelIndex(currentSong.sepModel)].tracks;
        }
        if (tracks) {
          const zip = new JSZip();
          const requests = tracks.map((track) => {
            zip.file(
              originalTitle ? `${originalTitle}_${track.name}` : track.name,
              track.blob
            );
            return null;
          });
          await Promise.all(requests);
          await zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(
              content,
              originalTitle ? `${originalTitle}_tracks.zip` : "tracks.zip"
            );
          }).then(() => {
            setDownloadfinished(true);
          });
        }
      }
    }
  };

  return (
    <div className="separation-tool-main-container">
      {downloadOnly ? (
        <div>
        {completpercentage === 100 ? (downloadfinished? "Download complete" : "Zipping files...") : `Downloading tracks... ${completpercentage}%`}
      </div>
      ) : (
        <>
          {!incorrectId && !deleted ? (
            <>
              <SeparationToolHeader
                isFolded={isSepToolOpen}
                setIsFolded={setIsSepToolOpen}
                zoom={zoom}
                minZoom={minZoom}
                maxZoom={maxZoom}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
                setZoom={setZoom}
              />
              {isSepToolOpen && (
                <>
                  <div className="separation-tool-content-container">
                    <div className="separation-tool-content-time-line-container"></div>
                    <OutputsList zoom={zoom} />
                    <div className="separation-tool-footer-main-container">
                      <div className="separation-tool-footer-scroller-container">
                        <SimpleSVGComponent
                          icon={downArrowIcon}
                          cursor={true}
                          alt="up-arrow"
                          width="0.7vw"
                          height="0.7vw"
                          style={{
                            transform: "rotate(180deg)",
                            filter: isOutputListAtTop ? "invert(1)" : "",
                          }}
                          onClick={() => {
                            const element = document.getElementById(
                              "source-sep-outputs-list"
                            );
                            if (element) {
                              element.scrollTo({
                                top: element.scrollTop - 100,
                                behavior: "smooth",
                              });
                            }
                          }}
                        />
                        <SimpleSVGComponent
                          icon={downArrowIcon}
                          cursor={true}
                          alt="down-arrow"
                          width="0.7vw"
                          height="0.7vw"
                          style={{
                            filter: isOutputListAtBottom ? "invert(1)" : "",
                          }}
                          onClick={() => {
                            const element = document.getElementById(
                              "source-sep-outputs-list"
                            );
                            if (element) {
                              element.scrollTo({
                                top: element.scrollTop + 100,
                                behavior: "smooth",
                              });
                            }
                          }}
                        />
                      </div>
                      { (
                      <div className="separation-tool-footer-download-all-container">
                        <CustomTooltip
                          title={<DownloadTooltip />}
                          placement="top"
                          open={showDownload}
                          arrow
                          width={1000}
                          slotProps={{
                            popper: {
                              sx: {
                                [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                  {
                                    marginBottom: "1vh",
                                    borderRadius: "0.4vw",
                                    filter:
                                      "drop-shadow(0px 0.2vw 0.4vw rgba(0, 0, 0, 0.25))",
                                    height: "13vh",
                                    width: "12vw",
                                    marginRight: "3vw",
                                  },
                              },
                            },
                          }}
                        >
                          <div
                            className="source-sep-footer-download-all-button-container"
                            style={{
                              backgroundColor: showDownload
                                ? theme.palette.black
                                : "",
                            }}
                            onClick={() => setShowDownload(!showDownload)}
                          >
                            <div className="source-sep-footer-download-all-button-title">
                              Download all
                            </div>
                            <div
                              className="source-sep-footer-download-all-button-icon"
                              style={{
                                backgroundColor: showDownload
                                  ? theme.palette.black
                                  : "",
                              }}
                            >
                              <SimpleSVGComponent
                                icon={downloadIcon}
                                alt="add-arrow-icon"
                                cursor={true}
                                height="0.8vw"
                                width="0.8vw"
                              />
                            </div>
                          </div>
                        </CustomTooltip>
                      </div>
                      )}
                    </div>
                    {!currentSelectedSepModelIsGenerated && (
                      <div className="separation-tool-main-drag-drop-overlay-container">
                        {!isAudioUploaded && !isModelSelected && (
                          <SepToolAudioUpload
                            setDroppedFiles={setDroppedFiles}
                            droppedFiles={droppedFiles}
                            acceptOnlyAudioFiles
                          />
                        )}
                        {!isModelSelected &&
                          isAudioUploaded &&
                          !currentSelectedSepModelIsGenerated && (
                            <SepToolUploadModelSelector
                              modalCallback={handleModalOpen}
                            />
                          )}
                      </div>
                    )}
                  </div>
                </>
              )}
              <SeparationToolFooter
                isSongUploaded={inputs.length > 0}
                id={urlParams.id}
                setDeleted={setDeleted}
              />
              <GenericModal
                handleModalClose={handleModalClose}
                open={modalIsOpen}
                element={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "cneter",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "1vw",
                    }}
                  >
                    <div className="share-modal-header">
                      <div className="share-modal-button-container">
                        <img src={ArrowIcon} alt="share-arrow-icon" />
                      </div>
                      <div className="generic-medium-bold-text">
                        Generation Pending...
                      </div>
                    </div>
                    <div className="share-modal-main-container">
                      <div className="share-modal-mail-header">
                        <img src={MailIcon} alt="mail-icon" />
                        <div className="generic-small-text">Email to</div>
                        <div className="share-modal-mail generic-small-bold-text">
                          {userEmail}
                        </div>
                      </div>
                      <div
                        className="generic-small-text"
                        style={{ marginTop: "0.4vw" }}
                      >
                        “Once the process is complete, a link to the separated
                        track{" "}
                        <span className="generic-small-bold-italic-text">
                          {currentSong?.title}
                        </span>{" "}
                        will be sent.“
                      </div>
                    </div>
                    <div
                      className="share-modal-footer"
                      style={{
                        width: "100%",
                        height: "2.5vh",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="generic-small-bold-italic-text">
                        Number of credits left: {credits - 1}
                      </span>
                      <GenericButton
                        onClick={() => {
                          handleModalClose();
                        }}
                        text="Close"
                        color="#3F4043"
                        hoverColor="#1A191E"
                        style={{
                          width: "4vw",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                }
              />
            </>
          ) : (
            <NotFoundPage text={deleted ? "Deleted project" : "Incorrect ID"} />
          )}
          {error && (
            <CustomSnackBar
              onClose={() => setError(undefined)}
              open={error.length > 0}
              text={error}
              severity="error"
            />
          )}
        </>
      )}
    </div>
  );
};

export { percentage };
export default SeparationToolPage;
