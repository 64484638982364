import SimpleSVGComponent from "../../components/UI/SimpleSVGComponent";
import "./MainPage.css";
import "../../components/UI/Generic.css";
import LibraryIcon from "../../assets/icons/library-icon.svg";
import SeparationToolIcon from "../../assets/icons/separation-tool-icon.svg";
import React, { useEffect } from "react";
import SeparationTool from "../SeparationToolPage/SeparationToolPage";
import { Library } from "../Library/Library";
import ProfilPage from "../ProfilPage/ProfilPage";
import { useNavigate } from "react-router-dom";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import { useKeyPress } from "../../hooks/UseKeyPress";
import {
  PlayerContext,
  PlayerContextProps,
} from "../../contexts/PlayerContext";
import {
  MultiPlayerContext,
  IMultiPlayerContext,
} from "../../contexts/MultiPlayerContext";

type DashboardSelection = "" | "library" | "septool";

const mvpMode = process.env.REACT_APP_MVP_MODE === "true";

interface MainPageProps {
  default?: DashboardSelection;
}

export const MainPage: React.FC<MainPageProps> = (props) => {
  const navigate = useNavigate();
  const { userToken } = React.useContext(UserContext) as UserContextProps;
  const [currentPage, setCurrentPage] = React.useState<DashboardSelection>(
    props.default ?? ""
  );
  const { cleanUpPlayers } = React.useContext(
    MultiPlayerContext
  ) as IMultiPlayerContext;
  const { cleanUpUniPlayer } = React.useContext(
    PlayerContext
  ) as PlayerContextProps;

  useKeyPress(
    ["k"],
    () => {
      cleanUpPlayers();
      setCurrentPage("septool");
    },
    [75]
  );

  useKeyPress(
    ["l"],
    () => {
      cleanUpPlayers();
      setCurrentPage("library");
    },
    [76]
  );

  useEffect(() => {
    if (!userToken) {
      navigate("/");
    }
  }, [navigate, userToken]);

  interface ToggleButtonProps {
    alt: string;
    icon: string;
    text: string;
    isSelected: boolean;
    onClick: (e: any) => void;
  }

  const ToggleButton = (props: ToggleButtonProps) => {
    const [isHover, setIsHover] = React.useState(false);

    return (
      <div
        className={
          "toggle-button" +
          (props.isSelected || isHover ? " toggle-button-selected" : "")
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={(e: any) => {
          navigate("/dashboard");
          props.onClick(e);
        }}
      >
        <SimpleSVGComponent
          style={{ transition: "ease-in-out 0.3s" }}
          alt={props.alt}
          icon={props.icon}
          height={"auto"}
          width={isHover || props.isSelected ? "1.5vw" : "1.3vw"}
        />
        <div
          style={{ transition: "ease-in-out 0.3s" }}
          className={
            isHover || props.isSelected
              ? "generic-medium-bold-text"
              : "generic-small-text"
          }
        >
          {props.text}
        </div>
      </div>
    );
  };

  return (
    <div className="main-page-container">
      <div className="main-page-toggle">
        <div
          className="toggle-left-container"
          style={
            currentPage === "septool"
              ? { height: "100%", paddingBottom: "1.5%" }
              : { cursor: "pointer" }
          }
        >
          <ToggleButton
            alt="separation-tool-icon"
            icon={SeparationToolIcon}
            text="Separation tool"
            isSelected={currentPage === "septool"}
            onClick={() => {
              cleanUpUniPlayer();
              setCurrentPage("septool");
            }}
          />
        </div>
        {/* <div
          className="toggle-right-container"
          style={
            currentPage === "library"
              ? { height: "100%", paddingBottom: "1.5%" }
              : { cursor: mvpMode ? "not-allowed" : "pointer" }
          }
        >
          <ToggleButton
            alt="library-icon"
            icon={LibraryIcon}
            text="Library"
            isSelected={currentPage === "library"}
            onClick={() => {
              if (mvpMode) return;
              cleanUpPlayers();
              setCurrentPage("library");
            }}
          />
        </div> */}
      </div>
      {currentPage === "septool" ? (
        <SeparationTool />
      ) : currentPage === "" ? (
        <ProfilPage />
      ) : (
        <Library />
      )}
    </div>
  );
};
