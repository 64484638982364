import { FC, useContext, useMemo, useState } from "react";
import "./Header.css";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import { isMobile } from "react-device-detect";
import SonyLogo from "../../assets/sony-logo.svg";
import SourceSepGif from "../../assets/gifs/source-sep.gif";
import SourceSepLogoName from "../../assets/source-sep-logo-name.png";
import BigRoundedButton from "../UI/BigRoundedButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelperTextContainer from "../UI/HelperTextContainer";
import { GenericButton } from "../UI/GenericButton";
import { useNavigate } from "react-router-dom";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import ImportantIcon from "../../assets/icons/important-icon.svg";
import enterIcon from "../../assets/keyboard/enter.svg";
import deleteIcon from "../../assets/keyboard/delete.svg";
import spaceIcon from "../../assets/keyboard/space.svg";
import horizontalArrowsIcon from "../../assets/keyboard/horizontal-arrows.svg";
import verticalArrowsIcon from "../../assets/keyboard/vertical-arrows.svg";
import KIcon from "../../assets/keyboard/k-letter.svg";
import PlusIcon from "../../assets/keyboard/plus.svg";
import minusIcon from "../../assets/keyboard/minus.svg";
import MIcon from "../../assets/keyboard/m-letter.svg";
import SIcon from "../../assets/keyboard/s-letter.svg";
import LIcon from "../../assets/keyboard/l-letter.svg";

const mvpMode = process.env.REACT_APP_MVP_MODE === "true";

export const Header: FC = () => {
  const [isHelperTextOpen, setIsHelperTextOpen] = useState(false);
  const { userToken, setUserToken } = useContext(
    UserContext
  ) as UserContextProps;
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("userToken");
    setUserToken(undefined);
    navigate("/");
    window.location.reload();
  };

  const helperText = useMemo(() => {
    return (
      <div className="helper-text-main-container">
        <div className="helper-text-up-container">
          <strong>Sony's AI Sound Separation</strong> is used by audio creatives
          and engineers to separate mixed audio sources into their component
          tracks using state-of-the-art algorithms based on deep neural
          networks. It has already been successfully tested with leading players
          in the entertainment industry, such as movie and music production
          companies.
        </div>
        <div className="helper-info-text">
          <img
            src={ImportantIcon}
            alt="info-icon"
            className="helper-important-icon"
          />
          We cannot guarantee that AI Sound Separation technology will always
          separate audio into component sources in a way you might wish or
          expect. We are continuously working to improve the technology. Thank
          you for your understanding.
        </div>
        <div>
          <strong className="generic-medium-bold-text">
            Upload formats: &nbsp; .WAV .MP3 FLAC
          </strong>
          <div>
            Good to know: You can also directly import full songs folders
          </div>
        </div>
        <div className="helper-text-down-container">
          <strong className="generic-medium-bold-text">
            KeyBoard Shortcuts
          </strong>
          <div style={{ display: "flex" }}>
            <div className="helper-text-down-box-container">
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="enter-icon"
                  icon={enterIcon}
                />
                Create a new stem
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="3.6rem"
                  alt="backspace-icon"
                  icon={deleteIcon}
                />
                Delete a stem
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="3.6rem"
                  alt="spacebar-icon"
                  icon={spaceIcon}
                />
                Play/Pause
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="horizental-arrow-icon"
                  icon={horizontalArrowsIcon}
                />
                Navigate through songs
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="vertical-arrow-icon"
                  icon={verticalArrowsIcon}
                />
                Navigate through stems
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="vertical-arrow-icon"
                  icon={KIcon}
                />
                Go to Separation tool
              </div>
            </div>
            <div className="helper-text-down-box-container">
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="inpainting-icon"
                  icon={PlusIcon}
                />
                Waveform zoom in
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="plus-icon"
                  icon={minusIcon}
                />
                Waveform zoom out
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="minus-icon"
                  icon={MIcon}
                />
                Mute
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="m-letter-icon"
                  icon={SIcon}
                />
                Solo
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="s-letter-icon"
                  icon={LIcon}
                />
                Go to Library
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="header-main-container">
      <div className="header-sony-container">
        {" "}
        {process.env.REACT_APP_SONY_TRADEMARKS === "true" && <SimpleSVGComponent alt="Sony logo" width="4.5rem" icon={SonyLogo} />}
      </div>
      <div className="header-source-sep-container">
        <div
          className="header-logo-and-name-container"
          style={{"cursor": "pointer"}}
          onClick={() => {
            logOut()
          }}
        >
          <div className="header-logo-background">
            <SimpleSVGComponent
              alt="source-sep-logo"
              width={"auto"}
              height="85%"
              icon={SourceSepGif}
            />
          </div>
          <SimpleSVGComponent
            alt="source-sep-logo-name"
            width={isMobile ? "100px" : "auto"}
            icon={SourceSepLogoName}
            height="30%"
          />
        </div>
        {!mvpMode && (
          <div className="header-buttons-container">
            {!userToken && (
              <GenericButton
                text="Log in"
                color="#1A191E"
                hoverColor="#3F4043"
                width="25%"
                onClick={() => navigate("/login")}
              />
            )}
            {!userToken && <GenericButton text="Sign up" width="25%"
            onClick={() => navigate("/register")} />}
            {userToken && (
              <GenericButton
                text="Log out"
                width="25%"
                color="#1A191E"
                hoverColor="#3F4043"
                onClick={logOut}
              />
            )}
            <div
              style={{ height: "2vh", width: "0.5%", backgroundColor: "white" }}
            ></div>
            <div className="header-help-container">
              <BigRoundedButton
                icon={<HelpOutlineIcon />}
                text={isMobile ? "" : "HELP"}
                onClick={() => setIsHelperTextOpen((state) => !state)}
                isSvg={false}
                isLoading={false}
                height={"85%"}
                width={isMobile ? "3rem" : "4.6vw"}
              />
              {isHelperTextOpen && (
                <HelperTextContainer
                  setIsOpen={setIsHelperTextOpen}
                  text={helperText}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
